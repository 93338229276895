import React, { useState, useEffect } from 'react';
import '../App.scss';
import '../scss/_home.scss';
import '../scss/_header.scss';
import AddProject from './AddProject';
import UtilityList from './UtilityList';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from './apiConfig';

import showToast from './ToastUtils';

const Home = ({ userData }) => {
  let disableButton = false;
  const navigate = useNavigate();
  const [projectList, setprojectList] = useState([]);
  const [workType, setWorkType] = useState([]);
  const [logList, setLogList] = useState([]);
  const [newLogList, setnewLogList] = useState([]);
  const [updateLoglist, setUpdateLogList] = useState([]);
  const [startDateString, setStartDateString] = useState();
  const [endDateString, setEndDateString] = useState();
  const [weeks, setWeeks] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      dates: [],
      inputValues: Array(7).fill('')
    }
  ]);

  const updateCurrentWeek = () => {
    const today = new Date();
    const currentDay = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const mondayOffset = (currentDay + 6) % 7; // Offset to Monday
    const mondayDate = new Date(today);
    mondayDate.setDate(today.getDate() - mondayOffset);
    const startDate = mondayDate;
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return {
      startDate,
      endDate,
      dates: generateWeekDates(startDate),
      inputValues: Array(7).fill('')
    };
  };

  const generateWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(start);
      currentDate.setDate(start.getDate() + i);
      dates.push(currentDate);
    }
    return dates;
  };

  useEffect(() => {
    const updatedWeeks = [updateCurrentWeek()];
    setWeeks(updatedWeeks);

    const startDate = updatedWeeks[0].startDate;
    const endDate = updatedWeeks[0].endDate;

    // Format: YYYY-MM-DD
    const startDateInString = startDate.toISOString().split('T')[0];
    const endDateInString = endDate.toISOString().split('T')[0];

    setStartDateString(startDateInString);
    setEndDateString(endDateInString);
    fetchProjectData();
  }, []);

  /* useEffect to trigger fetchLogData when startDateString and endDateString are set */
  useEffect(() => {
    if (startDateString && endDateString) {
      fetchLogData(startDateString, endDateString);
    }
  }, [startDateString, endDateString]);

  /* Get project details from server */
  const fetchProjectData = async () => {
    try {
      const response = await axios.get(`${apiUrl}?identifier=get-project`, {
        withCredentials: true
      });

      /* Convert the projectData object to an array of objects */
      const projectData = response.data.data;

      setWorkType(projectData.type);
      setprojectList(projectData.client);
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  /* Fetch log details from server */
  const fetchLogData = async (startDateString, endDateString) => {
    try {
      const response = await axios.get(`${apiUrl}?identifier=get-log&start=${startDateString}&end=${endDateString} `, { withCredentials: true });

      const logData = response.data.data;

      const logArray = Object.values(logData);
      setLogList(logArray);
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  const addProject = (userInput) => {
    let copy = [...projectList];

    copy = [...copy, { id: projectList.length + 1, project_name: userInput }];
    setprojectList(copy);
  };

  /* Function to check if a log already exists with the same project ID, client ID, and type ID */
  const logExist = (existingLog, newLog) => {
    return existingLog.project_id === newLog.project_id && existingLog.client_id === newLog.client_id && existingLog.type_id === newLog.type_id;
  };

  const addLog = ({ project_id, client_id, client_name, date, log_id, time, project_name, rate, type_id, type_name }) => {
    /* Check if any log with the same project ID, client ID, and type ID already exists */
    const existingLogIndex = logList.findIndex((log) =>
      logExist(log, {
        project_id,
        client_id,
        type_id
      })
    );
    if (existingLogIndex !== -1) {
      /* creating  logs with time for the date, for updating in UI */
      const updatedLogList = [...logList];
      updatedLogList[existingLogIndex].logs.push({
        date,
        log_id,
        time
      });

      setLogList(updatedLogList);

      /*  creating as a new log for storing in database */
      const copynewLogList = [
        ...newLogList,
        {
          client_id: client_id,
          client_name: client_name,
          logs: [
            {
              date: date,
              log_id: log_id,
              time: time
            }
          ],
          project_id: project_id,
          project_name: project_name,
          rate: rate,
          type_id: type_id,
          type_name: type_name
        }
      ];
      setnewLogList(copynewLogList);
    } else {
      /* If no log with the same project ID, client ID, and type ID exists, add the new log to logList */

      const copyLog = [
        ...logList,
        {
          client_id: client_id,
          client_name: client_name,
          logs: [
            {
              date: date,
              log_id: log_id,
              time: ''
            }
          ],
          project_id: project_id,
          project_name: project_name,
          rate: rate,
          type_id: type_id,
          type_name: type_name
        }
      ];
      setLogList(copyLog);
    }
  };

  const handleAddTime = (log, newTime) => {
    /* Check if the log entry already exists in the logList */

    const existingLogIndex = logList.findIndex(
      (item) => item.project_name === log.project_name && item.date === log.date && item.type === log.type && item.details === log.details && item.id === log.id
    );

    if (existingLogIndex !== -1) {
      /*  If the log entry exists, update the existing entry with the new time */
      const updatedLogList = [...logList];
      updatedLogList[existingLogIndex] = {
        ...logList[existingLogIndex],
        time: newTime
      };

      setLogList(updatedLogList);
    } else {
      /* If the log entry doesn't exist, create a new entry with the new time */
      const newLog = {
        ...log,
        time: newTime
      };

      setLogList((prevLogList) => [...prevLogList, newLog]);
    }
  };

  const handleSave = async () => {
    if (!disableButton) {
      disableButton = true;
      try {
        /* Construct the request body or parameters based on the structure expected by the API */
        const requestBody = {
          logs: newLogList,
          identifier: 'update-log'
        };

        /* Make a POST request to the API endpoint */
        const response = await fetch(apiUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody) /* Convert request body to JSON string */
        });
        disableButton = true;
        if (response.status === 401) {
          navigate('/#login');
        }
        if (response.ok) {
          showToast('success', 'Data added Successfully');
          setnewLogList([]);
          fetchLogData(startDateString, endDateString);
        } else {
          showToast('error', 'Failed to send logs');
          console.error('Failed to send logs:', response.statusText);
        }
      } catch (error) {
        disableButton = false;
        console.error('Error sending logs:', error);
      }
    }
  };

  const handleUpdateTime = (logId, updatedTime) => {
    /* Check if the log with logId is already present in the updateLoglist */
    const logIndex = updateLoglist.findIndex((log) => log.id === logId);

    if (logIndex !== -1) {
      /* If the log is already in the list, update its time */
      const updatedLogs = updateLoglist.map((log) => (log.id === logId ? { ...log, time: updatedTime } : log));

      setUpdateLogList(updatedLogs);
    } else {
      /* If the log is not in the list, add it with the updated time */
      const newLog = {
        id: logId,
        time: updatedTime
      };

      setUpdateLogList((prevUpdateLogList) => [...prevUpdateLogList, newLog]);
    }

    handleAddTime(logId, updatedTime);
  };

  /* Function to hide AddProject button */
  const isWeekGreaterThanOrEqual = (weekStartDate, referenceDate) => {
    return (
      weekStartDate.getFullYear() > referenceDate.getFullYear() ||
      (weekStartDate.getFullYear() === referenceDate.getFullYear() && weekStartDate.getMonth() > referenceDate.getMonth()) ||
      (weekStartDate.getFullYear() === referenceDate.getFullYear() &&
        weekStartDate.getMonth() === referenceDate.getMonth() &&
        weekStartDate.getDate() >= referenceDate.getDate())
    );
  };

  const isCurrentWeekGreaterThanRenderedWeek = () => {
    const renderedWeekStartDate = new Date(weeks[0].startDate);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 7); /* Get yesterday's date */

    const currentWeekStartDate = new Date(updateCurrentWeek().startDate);

    const isRenderedWeekGreaterThanOrEqualToYesterday = isWeekGreaterThanOrEqual(renderedWeekStartDate, yesterday);
    const isRenderedWeekGreaterThanOrEqualToCurrentWeek = isWeekGreaterThanOrEqual(renderedWeekStartDate, currentWeekStartDate);

    return isRenderedWeekGreaterThanOrEqualToYesterday || isRenderedWeekGreaterThanOrEqualToCurrentWeek;
  };

  return (
    <div className="home-page">
      <div className="wrap">
        <div className="content-container">
          <UtilityList
            logList={logList}
            newLogList={newLogList}
            handleAddTime={handleAddTime}
            handleUpdateTime={handleUpdateTime}
            projectList={projectList}
            setnewLogList={setnewLogList}
            setLogList={setLogList}
            weeks={weeks}
            setWeeks={setWeeks}
            generateWeekDates={generateWeekDates}
            addLog={addLog}
            startDateString={startDateString}
            setStartDateString={setStartDateString}
            endDateString={endDateString}
            setEndDateString={setEndDateString}
            fetchLogData={fetchLogData}
          ></UtilityList>
          <div className="btn-wrapper">
            {isCurrentWeekGreaterThanRenderedWeek() && (
              <>
                <AddProject addProject={addProject} addLog={addLog} projectList={projectList} workType={workType} logList={logList}></AddProject>
                <button className="save-btn" onClick={handleSave}>
                  Save Log
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
