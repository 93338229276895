import React from 'react';
import '../../../scss/pipeline_styles/_button.scss';

const Button = ({ title, showIcon, handleClick, fullWidth, buttonStyle }) => {
  let buttonClassName = 'button';
  if (fullWidth) {
    buttonClassName += ' full-width';
  }
  if (buttonStyle) {
    buttonClassName += ' ' + buttonStyle;
  }
  return (
    <div className={buttonClassName}>
      <button onClick={handleClick}>
        {showIcon ? (
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
              stroke="#272829"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M9 6V12" stroke="#272829" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 9H12" stroke="#272829" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ) : (
          ''
        )}
        {title}
      </button>
    </div>
  );
};

export default Button;
