import React from 'react';
import '../../../../scss/pipeline_styles/_task.scss';
import User from '../../common/User';
import DateComponent from '../../common/DateComponent';

const Task = (props) => {
  return (
    <div className={`task${props.status === 'assigned' && !props.myBoard ? ' assigned' : ''}`}>
      <div className="task__title">{props.title}</div>
      <div className="task__details">
        <User icon={props.assignedProfileImage} name={props.assignedName} />
        <DateComponent date={props.deadline} status={props.status} flag={true} />
      </div>
    </div>
  );
};

export default Task;
