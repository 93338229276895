import React from 'react';
import '../../../scss/pipeline_styles/_search.scss';

const Search = ({ setSearchTerm, searchTerm, handleSearchButtonClick, isSearchExpanded }) => {
  return (
    <form onSubmit={handleSearchButtonClick} className={`search__form ${isSearchExpanded ? 'active' : ''}`}>
      <input
        type="text"
        className="search__input"
        placeholder="Search boards (#client, @tags)"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button className="search__button">
        <img src="./image/search.svg" alt="search" />
      </button>
    </form>
  );
};

export default Search;
