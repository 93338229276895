import React from 'react';
import '../../../../scss/pipeline_styles/_filter.scss';
import Tab from '../../common/Tab';
import Search from '../../common/Search';

const Filter = ({ totalCount, mineCount, currentTab, setCurrentTab, setSearchTerm, searchTerm, handleSearchButtonClick, isSearchExpanded }) => {
  return (
    <div className="filter">
      <div className="filter_tabs">
        <Tab title={'Assigned to me'} count={mineCount} id={'me'} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <Tab title={'All'} count={totalCount} id={'all'} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>
      <Search setSearchTerm={setSearchTerm} searchTerm={searchTerm} handleSearchButtonClick={handleSearchButtonClick} isSearchExpanded={isSearchExpanded} />
    </div>
  );
};

export default Filter;
