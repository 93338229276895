import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from './../apiConfig';
import '../../scss/pipeline_styles/_pipeline-home.scss';
import Sidebar from './Sidebar/Sidebar';
import TaskCard from './taskcard/TaskCard';
import AdminBar from './adminbar/AdminBar';
import { useParams } from 'react-router-dom';
import showToast from '../ToastUtils';

const PipelineHomePage = ({ userData, setUserData }) => {
  const [filterUser, setFilterUser] = useState(false);
  const [filterClient, setFilterClient] = useState(false);
  const [sortPriority, setSortPriority] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  let tab = 'me';
  if (userData.roles >= 100) {
    tab = 'all';
  }

  const [currentTab, setCurrentTab] = useState(tab);

  const params = useParams();

  const getTaskList = (id) => {
    let url = apiUrl + '?action=pipeline&identifier=get-pipeline-task';
    if (userData.roles >= 100) {
      url = apiUrl + '?action=pipeline&identifier=get-admin-pipeline-task';
    }
    axios
      .get(`${url}`, {
        withCredentials: true
      })
      .then((res) => {
        if (res.data.status === 'success') {
          const json = res.data.data;
          const arr = [];
          Object.keys(json).forEach(function (key) {
            arr.push(json[key]);
          });
          setTasks(arr);
          if (id && json[id]) {
            setShowTask(true);
            setSelectedTask(json[id]);
          }

          /* Sort array to get my assigned list */
          const userId = userData.id;
          const myList = arr.filter((item) => item.assignedId === userId && item.status === 'assigned');
          setMyTasks(myList);
        }
      });
  };

  const filteredTask = tasks.filter((task) => {
    const showTasks = task.status === 'active' || task.status === 'assigned' || task.status === 'requested';
    return showTasks;
  });

  const handleTaskClick = (task) => {
    setShowTask(true);
    if (task === 'new') {
      setSelectedTask(false);
    } else {
      setSelectedTask(task);
    }
  };

  const onDoubleClickTaskHandler = (task) => {
    if (currentTab === 'all' || currentTab === 'me') {
      if (task.status === 'active') {
        axios
          .get(`${apiUrl}?action=pipeline&identifier=self-assign&taskId=${task.id}`, {
            withCredentials: true
          })
          .then((res) => {
            if (res.data.status === 'success') {
              getTaskList(res.data.message);
              showToast('success', res.data.message);
            } else {
              showToast('error', res.data.message);
            }
          });
      }
    } else {
      let identifier = 'add-onhold';
      let message = 'This task was put on hold by @[@' + userData.slug + '](' + userData.id + ')';

      if (currentTab === 'archive') {
        identifier = 'close-task';
        message = 'This task was closed by @[@' + userData.slug + '](' + userData.id + ')';
      }

      let taskDetails = {
        user: userData.id,
        taskId: task.id,
        message: message
      };

      const requestBody = {
        action: 'pipeline',
        identifier: identifier,
        task: taskDetails
      };

      /* Make a POST request to the API endpoint */
      axios
        .post(apiUrl, requestBody)
        .then((res) => {
          if (res.data.status === 'success') {
            getTaskList(res.data.pipelineId);
            showToast('success', res.data.message);
          } else {
            showToast('error', res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          showToast('error', err.message);
        });
    }
  };

  const onDoubleClickReturnTaskHandler = (task) => {
    if (currentTab === 'all' || currentTab === 'me') {
      axios
        .get(`${apiUrl}?action=pipeline&identifier=return-assign&taskId=${task.id}`, {
          withCredentials: true
        })
        .then((res) => {
          if (res.data.status === 'success') {
            getTaskList(res.data.message);
            showToast('success', res.data.message);
          } else {
            showToast('error', res.data.message);
          }
        });
    } else {
      let identifier = 'remove-onhold';
      let message = 'This task was removed from on-hold by @[@' + userData.slug + '](' + userData.id + ')';

      if (currentTab === 'archive') {
        identifier = 'remove-archive';
        message = 'This task was opened by @[@' + userData.slug + '](' + userData.id + ')';
      }

      let taskDetails = {
        user: userData.id,
        taskId: task.id,
        message: message
      };

      const requestBody = {
        action: 'pipeline',
        identifier: identifier,
        task: taskDetails
      };

      /* Make a POST request to the API endpoint */
      axios
        .post(apiUrl, requestBody)
        .then((res) => {
          if (res.data.status === 'success') {
            getTaskList(res.data.pipelineId);
            showToast('success', res.data.message);
          } else {
            showToast('error', res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          showToast('error', err.message);
        });
    }
  };

  const updateOnsubmit = (id) => {
    if (id) {
      getTaskList(id);
    } else {
      getTaskList(false);
      setShowTask(false);
    }
  };

  /* Call API */
  useEffect(() => {
    if (params && params.id) {
      getTaskList(params.id);
    } else {
      getTaskList(false);
    }
  }, []);

  return (
    <div className={`pipeline-page${userData.roles >= 100 ? ' admin-page' : ''}`}>
      {userData.roles >= 100 ? (
        <AdminBar
          count={filteredTask.length}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          filterUser={filterUser}
          setFilterUser={setFilterUser}
          filterClient={filterClient}
          setFilterClient={setFilterClient}
          sortPriority={sortPriority}
          setSortPriority={setSortPriority}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      ) : (
        ''
      )}
      <div className="wrap">
        <div className="pipeline__content">
          <Sidebar
            tabId={'main'}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            userData={userData}
            showAddButton={true}
            showFilterBar={userData.roles >= 100 ? false : true}
            tasks={tasks}
            handleTaskClick={handleTaskClick}
            onDoubleClickTaskHandler={onDoubleClickTaskHandler}
            filterUser={filterUser}
            filterClient={filterClient}
            sortPriority={sortPriority}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          {currentTab === 'me' || currentTab === 'all' ? (
            <Sidebar
              tabId={'mine'}
              userData={userData}
              showAddButton={false}
              showFilterBar={false}
              tasks={myTasks}
              handleTaskClick={handleTaskClick}
              onDoubleClickTaskHandler={onDoubleClickReturnTaskHandler}
              myBoard={true}
            />
          ) : (
            ''
          )}
          {currentTab === 'archive' ? (
            <Sidebar
              tabId={'archive'}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              userData={userData}
              showAddButton={false}
              showFilterBar={false}
              tasks={tasks}
              handleTaskClick={handleTaskClick}
              onDoubleClickTaskHandler={onDoubleClickReturnTaskHandler}
              filterUser={filterUser}
              filterClient={filterClient}
              sortPriority={sortPriority}
              searchTerm={searchTerm}
            />
          ) : (
            ''
          )}
          {currentTab === 'onhold' ? (
            <Sidebar
              tabId={'onhold'}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              userData={userData}
              showAddButton={false}
              showFilterBar={false}
              tasks={tasks}
              handleTaskClick={handleTaskClick}
              onDoubleClickTaskHandler={onDoubleClickReturnTaskHandler}
              searchTerm={searchTerm}
            />
          ) : (
            ''
          )}
          {showTask && (
            <div className="taskcard__wrap">
              <TaskCard task={selectedTask} userData={userData} updateOnsubmit={updateOnsubmit} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PipelineHomePage;
