import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from './../../apiConfig';
import '../../../scss/pipeline_styles/_adminbar.scss';

import Tab from '../common/Tab';
import Sort from '../common/Sort';

const AdminBar = ({
  count,
  currentTab,
  setCurrentTab,
  filterUser,
  setFilterUser,
  filterClient,
  setFilterClient,
  sortPriority,
  setSortPriority,
  searchTerm,
  setSearchTerm
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showClientList, setShowClientList] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [showPriorityList, setShowPriorityList] = useState(false);
  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const sortList = [
    {
      id: 'deadline',
      username: 'Sort by Deadline'
    }
  ];

  /* Call API */
  useEffect(() => {
    /* Get users for assignment */
    axios
      .get(`${apiUrl}?identifier=get-users`, {
        withCredentials: true
      })
      .then((res) => {
        const userArray = res.data.data;
        const modifiedData = userArray.map((item) => ({
          id: item.user_id,
          username: item.user_name,
          avatar: item.profile_image
        }));
        setUserList(modifiedData);
      });
    /* Get client for assignment */
    axios
      .get(`${apiUrl}?identifier=get-active-client&action=pipeline`, {
        withCredentials: true
      })
      .then((res) => {
        console.log(res);
        const clientArray = res.data.data;
        const modifiedData = clientArray.map((item) => ({
          id: item.client_id,
          username: item.client_name
        }));
        setClientList(modifiedData);
      });
  }, []);

  const setShowList = (listType) => {
    if (listType === 'client') {
      setShowClientList(!showClientList);
      setShowUserList(false);
      setShowPriorityList(false);
    } else if (listType === 'user') {
      setShowClientList(false);
      setShowUserList(!showUserList);
      setShowPriorityList(false);
    } else if (listType === 'sort') {
      setShowClientList(false);
      setShowUserList(false);
      setShowPriorityList(!showPriorityList);
    }
  };

  return (
    <div className="adminbar">
      <div className="wrap">
        <Tab title={'All'} count={count} id={'all'} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <Tab title={'Assigned to me'} id={'me'} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <Tab title={'Archive'} id={'archive'} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <Tab title={'On Hold'} id={'onhold'} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <Sort
          type={'client'}
          showList={showClientList}
          list={clientList}
          setShowList={setShowList}
          selectedValue={filterClient}
          setSelectedValue={setFilterClient}
        />
        <Sort type={'user'} showList={showUserList} list={userList} setShowList={setShowList} selectedValue={filterUser} setSelectedValue={setFilterUser} />
        <Sort
          type={'sort'}
          showList={showPriorityList}
          list={sortList}
          setShowList={setShowList}
          selectedValue={sortPriority}
          setSelectedValue={setSortPriority}
        />
        <div className={`admin__search ${showSearch ? 'active' : ''}`}>
          <input type="text" className="admin__search--input" placeholder="Search boards" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          <button className="admin__search--button" onClick={() => setShowSearch(!showSearch)}>
            <img src="./image/search.svg" alt="search" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminBar;
