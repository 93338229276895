import React from 'react';
import '../../../scss/pipeline_styles/_user.scss';

const User = ({ icon, name }) => {
  return (
    <div className="user">
      <img src={icon} alt={`${name}'s profile`} className="user__image" />
      <p className="user__name">{name}</p>
    </div>
  );
};

export default User;
