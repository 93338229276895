import React, { useState } from 'react';
import '../../../scss/pipeline_styles/_sidebar.scss';
import Button from '../common/Button';
import Task from './task/Task';
import Filter from './filter/Filter';

const Sidebar = ({
  tabId,
  currentTab,
  setCurrentTab,
  showAddButton,
  showFilterBar,
  tasks,
  userData,
  handleTaskClick,
  onDoubleClickTaskHandler,
  myBoard,
  filterUser,
  filterClient,
  sortPriority,
  searchTerm,
  setSearchTerm
}) => {
  const buttonTitle = 'Add a Task';
  const totalCount = tasks.length;

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const addNewTask = () => {
    handleTaskClick('new');
  };

  const handleSearchButtonClick = (event) => {
    event.preventDefault();
    setSearchTerm('');
    setIsSearchExpanded(!isSearchExpanded);
  };

  const filteredAndSortedTasks = tasks
    .filter((task) => {
      let titleMatch = true;
      let nameMatch = true;
      let assignedNameMatch = true;
      let clientSlugMatch = true;
      if (searchTerm) {
        titleMatch = task.title.toLowerCase().includes(searchTerm.toLowerCase());
        nameMatch = task.name.toLowerCase().includes(searchTerm.toLowerCase());
        assignedNameMatch = task.assignedName.toLowerCase().includes(searchTerm.toLowerCase());
        clientSlugMatch = task.clientSlug.toLowerCase().includes(searchTerm.toLowerCase());
      }
      const mytasks = currentTab === 'me' ? task.assignedId === userData.id : true;
      let showTasks = task.status === 'active' || task.status === 'assigned' || task.status === 'requested';
      if (tabId === 'onhold') {
        showTasks = task.status === 'onhold';
      } else if (tabId === 'archive') {
        showTasks = task.status === 'closed';
      }
      let userList = true;
      if (filterUser) {
        userList = task.assignedId === filterUser;
      }
      let clientList = true;
      if (filterClient) {
        clientList = task.client === filterClient;
      }

      return (titleMatch || nameMatch || assignedNameMatch || clientSlugMatch) && mytasks && showTasks && userList && clientList;
    })
    .sort((a, b) => {
      if (sortPriority === 'deadline') {
        const dateA = new Date(a.deadline);
        const dateB = new Date(b.deadline);
        return dateA - dateB;
      }
      return 0;
    });

  let title = 'Matsio Board';
  if (tabId === 'mine') {
    title = 'My Board';
  } else if (tabId === 'archive') {
    title = 'Archive';
  } else if (tabId === 'onhold') {
    title = 'On Hold';
  }

  return (
    <div className="sidebar">
      <p className="sidebar__title">{title}</p>
      <div className="sidebar__body">
        {showFilterBar ? (
          <div className="sidebar__body--wrap">
            <Filter
              totalCount={totalCount}
              mineCount={0}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              handleSearchButtonClick={handleSearchButtonClick}
              isSearchExpanded={isSearchExpanded}
            />
          </div>
        ) : (
          ''
        )}
        {showAddButton ? (
          <div className="sidebar__body--wrap">
            <Button title={buttonTitle} showIcon={true} handleClick={addNewTask} fullWidth={true} buttonStyle={'yellow'} />
          </div>
        ) : (
          ''
        )}
        <div className={`sidebar__taskcontainer ${showAddButton ? 'addbutton' : ''} ${showFilterBar ? 'filterbutton' : ''}`}>
          {filteredAndSortedTasks.map((task, index) => (
            <div key={task.id} onClick={() => handleTaskClick(task)} onDoubleClick={() => onDoubleClickTaskHandler(task)}>
              <Task id={index} {...task} myBoard={myBoard} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
