import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import DateComponent from "../common/DateComponent";
import "react-datepicker/dist/react-datepicker.css";
import "../../../scss/pipeline_styles/_taskcard.scss";
import { apiUrl } from "./../../apiConfig";
import { MentionsInput, Mention } from "react-mentions";
import showToast from "../../ToastUtils";

function TaskCard({ task, userData, setUserData, updateOnsubmit }) {
  const [title, setTitle] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState("");
  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [description, setDescription] = useState("");
  const [showUserList, setShowUserList] = useState(false);
  const [showClientList, setShowClientList] = useState(false);
  const placeholder =
    "Enter your comments here. Use @ to mention team member, # for client";

  const getFlagColor = () => {
    const currentDate = new Date();
    if (task) {
      const deadlineDate = new Date(task.deadline);
      const timeDifferenceInMilliseconds = deadlineDate - currentDate;
      const daysDifference = Math.floor(
        timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
      );
      if (task) {
        if (daysDifference < 1) {
          return "#FF828D";
        } else if (daysDifference < 3) {
          return "#FFAC4B";
        } else {
          return "#00C97A";
        }
      } else {
        return "#FFF";
      }
    }
    return "#FFF";
  };

  const handleClientInputChange = (e) => {
    const input = e.target.value;
    setClientName(input);
    setShowClientList(true);
  };

  const handleClientSelect = (client) => {
    setClientName(client.display);
    setClientId(client.id);
    setShowClientList(false);
  };

  const handleClientSelectChange = (client) => {
    setClientName(client.display);
    setClientId(client.id);
    setShowClientList(false);
    updateClient(client.id, client.display);
  };

  const handleDateChange = (date) => {
    const previousDate = deadline;
    setDeadline(date);
    updateDeadline(date, previousDate);
  };

  const updateDeadline = async (deadline, previousDate) => {
    try {
      var requestBody;
      if (task) {
        const taskDetails = {
          user: userData.slug,
          taskId: task.id,
          deadline: deadline,
          previous: previousDate,
        };
        requestBody = {
          action: "pipeline",
          identifier: "update-deadline",
          task: taskDetails,
        };

        /* Make a POST request to the API endpoint */
        axios
          .post(apiUrl, requestBody)
          .then((response) => {
            if (response.data.status === "success") {
              showToast("success", response.data.message);
              const id = response.data.pipelineId;
              updateOnsubmit(id);
            } else {
              showToast("error", response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast("error", err.message);
          });
      }
    } catch (error) {
      console.error("Error sending logs:", error);
    }
  };

  const updateClient = async (id, name) => {
    try {
      var requestBody;
      if (task) {
        const taskDetails = {
          user: userData.slug,
          taskId: task.id,
          clientId: id,
          clientName: name,
        };
        requestBody = {
          action: "pipeline",
          identifier: "update-client",
          task: taskDetails,
        };

        /* Make a POST request to the API endpoint */
        axios
          .post(apiUrl, requestBody)
          .then((response) => {
            if (response.data.status === "success") {
              showToast("success", response.data.message);
              const id = response.data.pipelineId;
              updateOnsubmit(id);
            } else {
              showToast("error", response.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast("error", err.message);
          });
      }
    } catch (error) {
      console.error("Error sending logs:", error);
    }
  };
  const handleRequestClose = (e) => {
    e.preventDefault();
    const message =
      "Request close done by @[@" + userData.slug + "](" + userData.id + ")";
    const taskDetails = {
      user: userData.id,
      message: message,
      taskId: task.id,
      client: task.client,
      deadline: task.deadline,
      status: task.status,
    };
    const requestBody = {
      action: "pipeline",
      identifier: "close-request-task",
      task: taskDetails,
    };

    /* Make a POST request to the API endpoint */
    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        if (response.data.status === "success") {
          showToast("success", response.data.message);
          const id = response.data.pipelineId;
          updateOnsubmit(id);
          setTitle("");
          setDescription("");
          setShowUserList(false);
        } else {
          showToast("error", response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!task && !deadline) {
      showToast("error", "Deadline is required");
      return;
    }
    try {
      var requestBody;
      if (task) {
        const taskDetails = {
          user: userData.id,
          message: description,
          taskId: task.id,
          client: task.client,
          assigned: task.assignedId,
          deadline: task.deadline,
          status: task.status,
        };
        requestBody = {
          action: "pipeline",
          identifier: "update-task",
          task: taskDetails,
        };
      } else {
        if (!deadline) {
          showToast("error", "Please set a deadline for the task");
          return;
        }
        const taskDetails = {
          user: userData.id,
          title: title,
          client: clientId,
          message: description,
          deadline: deadline,
        };
        requestBody = {
          action: "pipeline",
          identifier: "add-task",
          task: taskDetails,
        };
      }

      /* Make a POST request to the API endpoint */
      axios
        .post(apiUrl, requestBody)
        .then((response) => {
          if (response.data.status === "success") {
            showToast("success", response.data.message);
            const id = response.data.pipelineId;
            updateOnsubmit(id);
            setTitle("");
            setDescription("");
            setShowUserList(false);
          } else {
            showToast("error", response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
        });
    } catch (error) {
      console.error("Error sending logs:", error);
    }
  };

  const handleInput = (e, newValue, newPlainTextValue, mentions) => {
    setDescription(e.target.value);
    const filteredValues = mentions
      .filter((item) => item.display.startsWith("#"))
      .map((item) => item);
    if (filteredValues && filteredValues.length) {
      if (!clientId) {
        setClientId(filteredValues[0].id);
        setClientName(filteredValues[0].display);
      }
    }
  };

  const handleShowUserList = (e) => {
    e.preventDefault();
    setShowUserList(!showUserList);
  };

  const handleAssignUser = (user) => {
    if (user) {
      const message =
        "Done and assigned @[" + user.display + "](" + user.id + ")";
      const taskDetails = {
        user: userData.id,
        message: message,
        taskId: task.id,
        client: task.client,
        assigned: user.id,
        deadline: task.deadline,
        status: "active",
      };
      const requestBody = {
        action: "pipeline",
        identifier: "update-task",
        task: taskDetails,
      };

      /* Make a POST request to the API endpoint */
      axios
        .post(apiUrl, requestBody)
        .then((response) => {
          if (response.data.status === "success") {
            showToast("success", response.data.message);
            const id = response.data.pipelineId;
            updateOnsubmit(id);
            setTitle("");
            setDescription("");
            setShowUserList(false);
          } else {
            showToast("error", response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
        });
    }
  };

  const handleTaskClose = (e) => {
    e.preventDefault();
    const message =
      "This task was closed by @[@" + userData.slug + "](" + userData.id + ")";
    const taskDetails = {
      user: userData.id,
      message: message,
      taskId: task.id,
    };
    const requestBody = {
      action: "pipeline",
      identifier: "close-task",
      task: taskDetails,
    };

    /* Make a POST request to the API endpoint */
    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        if (response.data.status === "success") {
          updateOnsubmit(null);
          setTitle("");
          setDescription("");
          setShowUserList(false);
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
      });
  };

  /* Call API */
  useEffect(() => {
    /* Get users for assignment */
    axios
      .get(`${apiUrl}?identifier=get-users`, {
        withCredentials: true,
      })
      .then((res) => {
        const userArray = res.data.data;
        const modifiedData = userArray.map((item) => ({
          id: item.user_id,
          display: "@" + item.slug + " ",
          username: item.user_name,
          avatar: item.profile_image,
        }));
        setUserList(modifiedData);
      });
    /* Get client for assignment */
    axios
      .get(`${apiUrl}?identifier=get-clients`, {
        withCredentials: true,
      })
      .then((res) => {
        const clientArray = res.data.data;
        const modifiedData = clientArray.map((item) => ({
          id: item.client_id,
          display: "#" + item.client_slug + " ",
          username: item.client_name,
        }));
        setClientList(modifiedData);
      });
  }, []);

  useEffect(() => {
    if (task && task.clientSlug) {
      setClientName("#" + task.clientSlug);
    } else {
      setClientName("");
    }
    if (task && task.deadline) {
      setDeadline(task.deadline);
    }
  }, [task]);

  // useEffect(() => {
  //   if (task) {
  //     updateClient();
  //   }
  // }, [clientId]);

  return (
    <div className="new-task-wrap">
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <div className="new-task-top">
          <div className="new-task-top-left">
            {task ? (
              <p className="task-title">{task.title}</p>
            ) : (
              <input
                type="text"
                placeholder="Enter a title for this task..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                className="title"
              />
            )}
            <div className="top-info">
              {task ? (
                <div className="user-info">
                  <div className="profile-img">
                    <img src={task.profileImage} alt="Profile" />
                  </div>
                  <div className="created-user-name">{task.name}</div>
                </div>
              ) : (
                <div className="user-info">
                  <div className="profile-img">
                    <img src={userData.profile_image} alt="Profile" />
                  </div>
                  <div className="created-user-name">{userData.name}</div>
                </div>
              )}
              <div className="deadline-wrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M2.3335 8.75C2.3335 8.75 2.91683 8.16667 4.66683 8.16667C6.41683 8.16667 7.5835 9.33334 9.3335 9.33334C11.0835 9.33334 11.6668 8.75 11.6668 8.75V1.75001C11.6668 1.75001 11.0835 2.33334 9.3335 2.33334C7.5835 2.33334 6.41683 1.16667 4.66683 1.16667C2.91683 1.16667 2.3335 1.75001 2.3335 1.75001V8.75Z"
                    fill={getFlagColor()}
                    stroke={getFlagColor()}
                    strokeWidth="0.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.3335 12.8333V8.75"
                    stroke={getFlagColor()}
                    strokeWidth="0.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {task && task.deadline ? (
                  userData.roles >= 10 ? (
                    <DatePicker
                      selected={deadline}
                      onChange={(date) => handleDateChange(date)}
                      placeholderText="Set Deadline"
                      dateFormat="yyyy-MM-dd"
                    />
                  ) : (
                    <DateComponent date={task.deadline} />
                  )
                ) : (
                  <DatePicker
                    selected={deadline}
                    onChange={(date) => setDeadline(date)}
                    placeholderText="Set Deadline"
                    dateFormat="yyyy-MM-dd"
                  />
                )}
              </div>
              {task && task.clientSlug ? (
                userData.roles >= 10 ? (
                  <div className="client-name-input-container">
                    <input
                      type="text"
                      placeholder="#enter client name"
                      value={clientName}
                      onChange={handleClientInputChange}
                      required
                      className="client-name-input"
                    />
                    {showClientList && (
                      <ul className="client-list">
                        {clientList
                          .filter((client) =>
                            client.display.includes(clientName)
                          )
                          .map((client, index) => (
                            <li
                              key={client.id}
                              onClick={() => handleClientSelectChange(client)}
                              className="client-list-item"
                            >
                              {client.username}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="client-name-nochangeable">
                    #{task.clientSlug}
                  </div>
                )
              ) : (
                <div className="client-name-input-container">
                  <input
                    type="text"
                    placeholder="#enter client name"
                    value={clientName}
                    onChange={handleClientInputChange}
                    required
                    className="client-name-input"
                  />
                  {showClientList && (
                    <ul className="client-list">
                      {clientList
                        .filter((client) => client.display.includes(clientName))
                        .map((client, index) => (
                          <li
                            key={client.id}
                            onClick={() => handleClientSelect(client)}
                            className="client-list-item"
                          >
                            {client.username}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="textarea-wrap">
          {task && (
            <div className="recent-activities">
              <h3>Recent Activities</h3>
              <ul
                className={
                  task.status === "closed" ||
                  (task.status === "requested" &&
                    userData.user_type !== "admin")
                    ? "no-button"
                    : "yes-button"
                }
              >
                {task.recentActivities.map((activity, index) => (
                  <li key={index} className="each-recent-activity">
                    <div className="activity-meta">
                      <div className="activity-img-div">
                        <img src={activity.profileImage} alt={activity.name} />
                      </div>
                      <p className="assigned-user-name"> @{activity.slug}</p>
                      <div className="seperator"></div>
                      <time className="time-stamp">
                        <DateComponent date={activity.time} time={true} />
                      </time>
                    </div>
                    <p
                      className="activies-container"
                      dangerouslySetInnerHTML={{ __html: activity.description }}
                    ></p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {task.status === "closed" ? null : task.status === "requested" &&
            userData.user_type !== "admin" ? null : (
            <div>
              <div className="input-comment-wrap">
                <MentionsInput
                  className="input-mention"
                  value={description}
                  onChange={handleInput}
                  placeholder={placeholder}
                >
                  <Mention
                    trigger="@"
                    data={userList}
                    renderSuggestion={({ display, avatar, username }) => (
                      <div className="custom-mention">
                        <img src={avatar} alt={`${username}'s avatar`} />
                        <span>{username}</span>
                      </div>
                    )}
                  />
                  <Mention
                    trigger="#"
                    data={clientList}
                    renderSuggestion={({ display, username }) => (
                      <div className="custom-mention">
                        <span>{username}</span>
                      </div>
                    )}
                  />
                </MentionsInput>
              </div>
              <div className="form-button-div">
                <button type="submit" className="send-btn">
                  Send
                </button>
                {task && (
                  <div className="task-assign-button">
                    <div className="assign-button-wrap">
                      {task.status !== "requested" ||
                      userData.user_type === "admin" ? (
                        <button
                          className="done-btn"
                          onClick={(e) => handleShowUserList(e)}
                        >
                          Done and Assign
                        </button>
                      ) : null}
                      {showUserList && (
                        <ul className="user-list">
                          {userList.map((user, index) => (
                            <li
                              key={user.id}
                              onClick={() => handleAssignUser(user)}
                              className="user-list-item"
                            >
                              <img
                                src={user.avatar}
                                alt={`${user.username}'s avatar`}
                              />
                              <span>{user.username}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    {task.status === "requested" ? (
                      userData.roles >= 100 ? (
                        <button
                          className="request-btn"
                          onClick={(e) => handleTaskClose(e)}
                        >
                          Close
                        </button>
                      ) : null
                    ) : (
                      <button
                        className="request-btn"
                        onClick={(e) => handleRequestClose(e)}
                      >
                        Request Close
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default TaskCard;
