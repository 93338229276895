import React from 'react';
import '../../../scss/pipeline_styles/_tab.scss';

const Tab = ({ title, count, id, currentTab, setCurrentTab }) => {
  return (
    <button className={`tab ${currentTab === id ? 'active' : ''}`} onClick={() => setCurrentTab(id)}>
      <p>{title}</p>
      {count ? <span>{count}</span> : ''}
    </button>
  );
};

export default Tab;
