import React from 'react';
import moment from 'moment';
import '../../../scss/pipeline_styles/_datecomponent.scss';

function DateComponent({ date, status, time, flag }) {
  const current = new Date();
  const deadlineDate = new Date(date);

  const timeDifferenceInMilliseconds = deadlineDate - current;
  const daysDifference = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

  const currentDate = moment();
  const inputDate = moment(date, 'YYYY-MM-DD HH:mm:ss');

  const increasedDate = inputDate.add(5, 'hours').add(30, 'minutes');

  const isToday = increasedDate.isSame(currentDate, 'day');
  const isYesterday = increasedDate.isSame(currentDate.clone().subtract(1, 'day'), 'day');
  const isTomorrow = increasedDate.isSame(currentDate.clone().add(1, 'day'), 'day');

  let formattedDate;
  if (time) {
    const inputdate = new Date(increasedDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - inputdate) / 1000);
    if (diffInSeconds < 60) {
      formattedDate = `${diffInSeconds} secs ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      formattedDate = `${diffInMinutes} mins ago`;
    } else {
      formattedDate = moment(inputDate).format('MMM Do [at] h:mm:ss A');
    }
  } else {
    if (isToday) {
      formattedDate = 'Today';
    } else if (isYesterday) {
      formattedDate = 'Yesterday';
    } else if (isTomorrow) {
      formattedDate = 'Tomorrow';
    } else {
      formattedDate = increasedDate.format('MMMM DD');
    }
  }
  const getFlagColor = () => {
    if (status === 'requested') {
      return '#00C97A';
    } else {
      if (daysDifference < 1) {
        return '#FF828D';
      } else if (daysDifference < 3) {
        return '#FFAC4B';
      } else {
        return '#00C97A';
      }
    }
  };
  return (
    <div className="date">
      <span className="date__value">{status === 'requested' ? <p className="requested">Requested</p> : <div className="date">{formattedDate}</div>}</span>
      {flag ? (
        <span className="date__flag">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <g clipPath="url(#clip0_1592_2555)">
              <path
                d="M1.33337 5.00002C1.33337 5.00002 1.66671 4.66669 2.66671 4.66669C3.66671 4.66669 4.33337 5.33335 5.33337 5.33335C6.33337 5.33335 6.66671 5.00002 6.66671 5.00002V1.00002C6.66671 1.00002 6.33337 1.33335 5.33337 1.33335C4.33337 1.33335 3.66671 0.666687 2.66671 0.666687C1.66671 0.666687 1.33337 1.00002 1.33337 1.00002V5.00002Z"
                fill={getFlagColor()}
                stroke={getFlagColor()}
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M1.33337 7.33333V5" stroke={getFlagColor()} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_1592_2555">
                <rect width="8" height="8" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DateComponent;
